import { useState, useEffect } from 'react';
import { getBoundary } from 'vibemap-constants/dist/helpers.js'


const useFetchBoundary = (
    showBoundary,
    cityCurrent,
    setBoundariesCallback = () => { }
) => {
    const [boundaries, setBoundaries] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (showBoundary && cityCurrent && (boundaries == null || boundaries.length === 0)) {
            const fetchBoundary = async () => {
                try {
                    const boundaryObject = await getBoundary(cityCurrent?.slug);
                    const boundaries = boundaryObject?.the_geom;
                    setBoundaries(boundaries);
                    setBoundariesCallback(boundaries);
                    console.log('DEBUG boundaryObject ', boundaryObject, boundaries);
                } catch (error) {
                    console.error('Failed to fetch boundary:', error);
                    setError(error);
                }
            };

            fetchBoundary();
        }
    }, [showBoundary, cityCurrent, boundaries]);

    return { boundaries, error };
};

export default useFetchBoundary;